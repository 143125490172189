<template>
    <v-row class="ma-0">
        <v-col cols="6">
            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">New Amount</v-subheader>
            <v-text-field ref="amount" type="number" placeholder="New Amount" hide-details="auto" v-model.number="model.amount"
                :error-messages="$helpers.errorMsg('number', $v.model.amount, 'New Amount')"
                outlined  prepend-inner-icon="mdi-currency-usd" required
                @focus="$helpers.avoidInputScrollable" @keydown="$helpers.avoidInputArrowKeyPress"></v-text-field>
        </v-col>
         <v-col cols="12">
            <v-card-actions class="pa-0">
                <v-row class="ma-0 pa-3" align="center" justify="end">
                    <v-btn :disabled="loading" color="primary" class="text-capitalize mr-3" @click="openNotify()">Save</v-btn>
                    <v-btn :disabled="loading" color="secondary" class="text-capitalize" @click="$emit('enableAct', false)">Cancel</v-btn>
                </v-row>
            </v-card-actions>
        </v-col>
        <notify-modal :loading="loading" :modalData="modalData" :open="openModal" @save="saveNotify" @close="openModal = false"/>
    </v-row>
</template>

<script>
import { required, minValue } from "vuelidate/lib/validators";
import { mapActions } from 'vuex';

export default {
    props: ['modelData'],
    emits: ['enableAct'],
    components: {
        NotifyModal: () => import("@/pages/admin/sf-opportunity/components/NotifyModal"),
    },
    data: (vm) => ({
        model: {},
        openModal: false,
        loading: false,
        defaultModel: {},
        modalData: {
            name: 'save',
            items: [],
            tableNotFound: 'No Data Found!.',
            headers: [
                { text: 'Field', value: 'field' },
                { text: 'Previous', value: 'prev' },
                { text: 'New', value: 'new' }
            ]
        },
    }),
    validations: {
        model: {
            amount: { required, minValue: minValue(1)},
        }
    },
    watch: {
        modelData(newVal) {
            this.defaultModel = newVal;
        }
    },
    created() {
        this.defaultModel = this.modelData;
    },
    methods: {
        ...mapActions(['updateDonateAmt']),

        openNotify() {
            this.$v.$touch();
            if (this.$v && this.$v.$invalid) {
                this.$helpers.focusErrorElement(this.$v.model, this.$refs);
                return;
            }
            this.modalData.items = [{field: 'amount', prev: this.defaultModel.amount,
                new: this.model.amount }]
            this.openModal = true;
            this.$emit('enableAct', true);
        },
        saveNotify() {
            this.loading = true;
            this.updateDonateAmt({id: this.defaultModel.id, reqData: this.model}).then(resp => {
                this.openModal = false;
                this.loading = false;
                this.$emit('modelUpdate', {field: 'amount', value: this.model.amount });
                this.$emit('enableAct', false);
            }).catch(err =>  this.loading = false)
        }
    }
}
</script>